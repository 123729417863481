<template>
  <el-dialog
      :title="' 建立表格' "
      :close-on-click-modal="false"
      :visible.sync="visible">
    <el-form :model="dataForm" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px">
      <el-form-item label="所创建表的名称" :prop="dataForm.tableName" :rules="{pattern:/^[A-Za-z]+[A-Za-z1-9_]*$/, required: true, message: '请输入表名', trigger: 'blur'}">
        <el-input :disabled="this.show" v-model="dataForm.tableName" style="width: 90%"></el-input>
      </el-form-item>
      <el-row  scope="scope">
<!--        <label-wrap>自定义属性</label-wrap>-->
        <el-form-item label="添加字段">
          <div
              v-for="(col,index) in dataForm.col"
              :key="col.index"
          >
            <el-row>
              <el-col :span="7">
                <el-form-item :label="'中文名称'" :prop="'col.'+index+'.remark'" :rules="{required: true, message: '请输入中文名称', trigger: 'blur'}">
                  <el-input :disabled="col.id< colsize"
                            style="width: 95%; margin-bottom: 5px"
                            v-model="col.remark"
                            placeholder="请输入中文名称"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item :label="'英文名称'" :prop="'col.'+index+'.name'" :rules="{pattern:/^[A-Za-z_]+$/, required: true, message: '请输入英文名称', trigger: 'blur'}">
                  <el-input :disabled="col.id< colsize"
                            style="width: 95%; margin-bottom: 5px"
                            v-model="col.name"
                            placeholder="请输入英文名称"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item :label="'属性类型'" :prop="'col.'+index+'.type'" :rules="{required: true, message: '请选择类型', trigger: 'change'}">
                  <el-select :disabled="col.id<colsize"
                             style="width: 90%; margin-bottom: 5px"
                             v-model="col.type"
                             placeholder="请选择属性类型"
                  >
                    <el-option v-for="item in options"
                               :key="item.value"
                               :label="item.value"
                               :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-button :disabled="col.id<colsize"
                           type="danger"
                           size="small"
                           @click="deleteCol(index)"
                >删除
                </el-button>
              </el-col>
            </el-row>
          </div>
          <el-button v-if="!this.show"
              type="primary"
              size="small"
              @click="addCol()"
              round
          >添加
          </el-button>
        </el-form-item>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" :disabled="this.show">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {treeDataTranslate} from "@/utils";

export default {
  data () {
    return {
      show: false,
      visible: false,
      dataForm: {
        col: [
          {id:0, name:'id',type:'bigint',remark:'id' },
          {id:1, name:'name',type:'varchar',remark:'名称'},
          {id:2, name :'create_time',type:'date',remark: '创建时间'},
          {id:3, name:'create_user',type:'bigint',remark: '创建者id'},
          {id:4, name:'is_leaf',type: 'bool',remark: '是否为叶子节点'},
        ],
        tableName: '',
        id:0,
        hasHbasetable: false,
      },
      dataList: [],
      options:[
        {value:'varchar'},
        {value:'bigint'},
        {value:'bool'},
        {value:'date'},
      ],
      rules: {
        remark:[],
        name:[],
        type:[],
      },
      colsize:5
    }
  },

  created() {
    this.currOperator = JSON.parse(
        window.sessionStorage.getItem("user")
    ).userId;
    this.currOperatorName = JSON.parse(
        window.sessionStorage.getItem("user")
    ).username;
  },

  watch:{
    $route(to,form){
      console.log('test')
    }
  },
  methods: {
    updateColOptions(id, value)
    {
      for (let i = 0; i < this.dataForm.length; i++) {
        if (id === this.dataForm[i].id) {
          this.dataForm[i].name = value;
          break;
        }
      }
    },
    addCol(){
      this.dataForm.col.push({});
    },
    deleteCol(index){
      console.log(index)
      this.dataForm.col.splice(index,1);
      console.log(this.dataForm.col)
    },
    init (id,show,hasHbaseTable) {
      this.show=show;
      this.visible = true
      this.dataListLoading = true
      this.hasHbasetable=hasHbaseTable
      if(this.hasHbasetable===0)
      {
        this.reset(id)
        if(show===true){
          this.$http({
            url: this.$http.adornUrl('/leveltable/listByLevelId?levelId='+id),
            method: 'get',
          }).then(({data}) => {
            this.dataForm.col=data.data;
            for(let i=0;i<this.dataForm.col.length;i++)
              this.dataForm.col[i].id=0
            this.dataForm.tableName=data.tableName
          })
        }
      }
      else{
        this.resetWithHabseTable(id)
        if(show===true){
          this.$http({
            url: this.$http.adornUrl('/leveltable/listByLevelId?levelId='+id),
            method: 'get',
          }).then(({data}) => {
            this.dataForm.col=data.data;
            for(let i=0;i<this.dataForm.col.length;i++)
              this.dataForm.col[i].id=0
            this.dataForm.tableName=data.tableName
          })
        }
      }
    },
    reset(id){
      this.dataForm.tableName='';
      this.dataForm.col=[
        {id:0, name:'id',type:'bigint',remark:'id' },
        {id:1, name:'name',type:'varchar',remark:'名称'},
        {id:2, name :'create_time',type:'date',remark: '创建时间'},
        {id:3, name:'create_user',type:'bigint',remark: '创建者id'},
        {id:4, name:'is_leaf',type: 'bool',remark: '是否为叶子节点'},
        {id:5, name: 'type',type: 'varchar',remark: '属性类型'}
      ];
      this.colsize=6
      this.dataForm.id=id || 0;
      this.dataForm.userId=this.currOperator;
      this.dataForm.hasHbasetable=false;
    },
    resetWithHabseTable(id){
      this.dataForm.tableName='';
      this.dataForm.col=[
        {id:0, name:'id',type:'bigint',remark:'id' },
        {id:1, name:'name',type:'varchar',remark:'名称'},
        {id:2, name :'create_time',type:'date',remark: '创建时间'},
        {id:3, name:'create_user',type:'bigint',remark: '创建者id'},
        {id:4, name:'hbaseTableName',type: 'varchar',remark: 'hbase表名称'},
        {id:5, name:'is_leaf',type: 'bool',remark: '是否为叶子节点'},
        {id:6, name: 'type',type: 'varchar',remark: '属性类型'}
      ];
      this.colsize=7
      this.dataForm.id=id || 0;
      this.dataForm.userId=this.currOperator;
      this.dataForm.hasHbasetable=true
    },
    // 表单提交
    dataFormSubmit () {
      this.$confirm(`进行建表操作后，表格字段无法再次修改，是否确定提交？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            console.log(this.dataForm)
            this.$http({
              url: this.$http.adornUrl(`/leveltable/createTable`),
              method: 'post',
              data: this.dataForm
            }).then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
              }
            })
          }
        })
      })
    }

  }
}

</script>
